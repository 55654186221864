var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.disabled
    ? _c(
        "button",
        {
          staticClass: "notification-btn",
          class: _vm.className,
          style: { width: _vm.size + "px", height: _vm.size + "px" },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.data.click.apply(null, arguments)
            },
          },
        },
        [
          _c("svg-icon", {
            attrs: {
              name: "ic-bell",
              width: _vm.size / 2.5,
              height: _vm.size / 2.5,
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }